.ce-editorjsColumns_col {
  position: relative;
}

.ce-editorjsColumns_col::before {
  content: "";
  position: absolute;
  top: 0;
  right: -7px;
  width: 1px;
  height: 100%;
  background-color: lightgray;
}

.ce-editorjsColumns_col:last-child::before {
  display: none;
}

.ce-editorjsColumns_col {
  flex: 50%;
}

.ce-editorjsColumns_wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: row;

  .ce-toolbar__actions {
    z-index: 0;
  }

  .ce-toolbar {
    z-index: 4;
  }

  .ce-popover {
    z-index: 4000;
  }
}

@media (max-width: 800px) {
  .ce-editorjsColumns_wrapper {
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

/* 

These styles will also affect the parent editor!!!!!!

*/

.ce-inline-toolbar {
  z-index: 1000;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: calc(
    100% - 50px
  ); /* example value, adjust for your own use case */
}

/*   */
.ce-toolbar__actions {
  right: calc(100% + 30px);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

/* Would be better to remove --narrow mode */
/* Issue Raised */
/* // This causes an error which is good i think? */
.codex-editor--narrow .codex-editor__redactor {
  margin: 0;
}

/* Required to prevent clipping */
.ce-toolbar {
  z-index: 4;
}

.codex-editor {
  /* background:#f00 !important; */
  z-index: auto !important;
}
